import firebase from "firebase/app"
import "firebase/auth"

const app = firebase.initializeApp({
  apiKey: "AIzaSyCCRHpqSM8b4wmeFpFk1rh9TKxZXp4TP8A",
  authDomain: "loginsystem-39149.firebaseapp.com",
  projectId: "loginsystem-39149",
  storageBucket: "loginsystem-39149.appspot.com",
  messagingSenderId: "1093243858129",
  appId: "1:1093243858129:web:cfbc52bb7da3bcd4da29f0",
  measurementId: "G-96G38DQQ9E"
})

export const auth = app.auth()
export default app
