import React from "react"
import { Navbar, Nav, NavDropdown } from "react-bootstrap"

export default function Newspage() {
    return (
        <>
            <Navbar bg="dark" variant="dark"
                sticky="top" expand="sm" collapseOnSelect>
                <Navbar.Brand>Bestefars vidunderlige nettside</Navbar.Brand>

                <Navbar.Toggle className="coloring" />
                <Navbar.Collapse>
                <Nav>
                    <NavDropdown title="Products">
                    <NavDropdown.Item href="#products/tea">Tea</NavDropdown.Item>
                    <NavDropdown.Item href="#products/coffee">Coffee</NavDropdown.Item>
                    <NavDropdown.Item href="#products/chocolate">Chocolate</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#products/promo">Promo</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link href="/news">Nyheter</Nav.Link>
                    <Nav.Link href="#about-us">Om oss</Nav.Link>
                    <Nav.Link href="#contact-us">Kontaktinformasjon</Nav.Link>
                </Nav>
                </Navbar.Collapse>

            </Navbar>
            <div class="container-flex">
                <div class="row justify-content-center">
                    <h2 className="text-center mb-4">Nyheter</h2>
                </div>
                <div class="row justify-content-center">
                    <div class="col-sm-auto">
                        <a type="button" href="https://www.bt.no/" role="button" class="btn btn-info">BergensTidene</a>
                        <a type="button" href="https://www.fvn.no/" role="button" class="btn btn-info">Fædrelandsvennen</a>
                        <a type="button" href="https://www.ba.no/" role="button" class="btn btn-info">BergensAvisen</a>
                        <a type="button" href="https://www.dn.no/" role="button" class="btn btn-primary">DN</a>
                        <a type="button" href="https://e24.no/" role="button" class="btn btn-primary">E24</a>
                        <a type="button" href="https://finansavisen.no/" role="button" class="btn btn-primary">Finansavisen</a>
                        <a type="button" href="https://www.vg.no/" role="button" class="btn btn-danger">VG</a>
                        <a type="button" href="https://www.nrk.no/" role="button" class="btn btn-danger">NRK</a>
                        <a type="button" href="https://www.tv2.no/" role="button" class="btn btn-danger">TV2</a>
                        <a type="button" href="https://www.reddit.com/r/worldnews/" role="button" class="btn btn-danger">r/WorldNews</a>
                        <a type="button" href="https://www.dagen.no/" role="button" class="btn btn-danger">Dagen</a>
                        <a type="button" href="https://dinside.dagbladet.no/" role="button" class="btn btn-success">Din Side</a>
                        <a type="button" href="https://www.tv2.no/broom/" role="button" class="btn btn-success">Broom</a>
                        <a type="button" href="https://www.dagbladet.no/" role="button" class="btn btn-success">Dagbladet</a>
                        <a type="button" href="https://www.tu.no/" role="button" class="btn btn-success">Teknisk ukeblad</a>
                        <a type="button" href="https://etilbudsavis.no/" role="button" class="btn btn-success">Tilbudsaviser</a>
                        <a type="button" href="https://klassekampen.no/" role="button" class="btn btn-warning">Klassekampen</a>
                        <a type="button" href="https://www.miff.no/" role="button" class="btn btn-warning">MIFF</a>
                        <a type="button" href="https://www.nettavisen.no/" role="button" class="btn btn-warning">NettAvisen</a>
                    </div>
                </div>
                <h1 className="mb-4"></h1>
                <div class="row">
                    <div class="col-sm">
                        <h2 className="text-center mb-4">Været</h2>
                        <div class="row justify-content-center">
                            <a type="button" href="https://www.yr.no/nb/værvarsel/daglig-tabell/1-92416/Norge/Vestland/Bergen/Bergen" role="button" class="btn btn-dark">Bergen</a>
                            <a type="button" href="https://www.yr.no/nb/værvarsel/daglig-tabell/1-2376/Norge/Agder/Kristiansand/Kristiansand" role="button" class="btn btn-dark">Kristiansand</a>
                            <a type="button" href="https://www.yr.no/nb/værvarsel/daglig-tabell/1-2701430/Norge/Agder/åseral/Bortelid" role="button" class="btn btn-dark">Bortelid</a>
                            <a type="button" href="https://www.yr.no/nb/værvarsel/daglig-tabell/1-66792/Norge/Vestfold%20og%20Telemark/Vinje/Haukeliseter" role="button" class="btn btn-dark">Haukelidseter</a>
                            <a type="button" href="" role="button" class="btn btn-dark">Oslo</a>
                        </div>
                    </div>
                    <div class="col-sm">
                        <h2 className="text-center mb-4">Webcam</h2>
                        <div class="row justify-content-center">
                            <a type="button" href="https://www.bt.no/nyheter/i/557y1/torgallmenningen" role="button" class="btn btn-secondary">Torgallmenningen</a>
                            <a type="button" href="https://www.bortelid.no/veret" role="button" class="btn btn-secondary">Bortelid</a>
                            <a type="button" href="https://www.bt.no/nyheter/i/pqR9V/finse" role="button" class="btn btn-secondary">Finse stasjon</a>
                            <a type="button" href="https://spesial.fvn.no/i/p3q2o/index.html" role="button" class="btn btn-secondary">Varoddbrua</a>
                            <a type="button" href="https://longyearbyen.kystnor.no/" role="button" class="btn btn-secondary">Longyearbyen</a>
                        </div>
                    </div> 
                </div>
            </div>
            
                <h1 className="mb-4"></h1>
                <div class="row">
                    <div class="col-sm">
                        <h2 className="text-center mb-4">BussOversikt</h2>
                        <div class="row justify-content-center">
                            <a type="button" href="https://reise.skyss.no/stops/stop-group/NSR:StopPlace:31927" role="button" class="btn btn-warning">Bjørndalsbakken</a>
                            <a type="button" href="https://reise.skyss.no/planner/travel-plans?from-pos=60.360237,5.24258&to-pos=60.391194,5.325392" role="button" class="btn btn-warning">Bjørndalsbakken til Festplassen</a>
                            <a type="button" href="https://reise.skyss.no/planner/travel-plans?from-pos=60.360237,5.24258&to-pos=60.373007,5.359555" role="button" class="btn btn-warning">Bjørndalsbakken til Sykehus</a>
                        </div>
                    </div>
                    <div class="col-sm">
                        <h2 className="text-center mb-4">FlyOversikt</h2>
                        <div class="row justify-content-center">
                            <a type="button" href="https://avinor.no/flyplass/bergen/flyinformasjon/ankomster/" role="button" class="btn btn-info">Flesland Avgang/Ankomst</a>
                            <a type="button" href="https://avinor.no/flyplass/kristiansand/flytider/avganger/" role="button" class="btn btn-info">Kjevik Avgang/Ankomst</a>
                            <a type="button" href="https://www.flightradar24.com/58.93,8.09/7" role="button" class="btn btn-info">Fly Radar</a>
                        </div>
                    </div> 
                </div>
                <h1 className="mb-4"></h1>
                <div class="row">
                    <div class="col-sm">
                        <h2 className="text-center mb-4">Plankart Bergen</h2>
                        <div class="row justify-content-center">
                        <a type="button" href="https://kart.bergen.kommune.no/portal/apps/sites/#/bergenskart/app/96c3aa9efd2c4021b115b37c499eade4" role="button" class="btn btn-success">Plankart</a>
                        </div>
                    </div>
                    <div class="col-sm">
                        <h2 className="text-center mb-4">Kart</h2>
                        <div class="row justify-content-center">
                        <a type="button" href="https://www.norgeskart.no/#!?project=norgeskart&layers=1002&zoom=15&lat=6730685.90&lon=-37108.34" role="button" class="btn btn-success">Norgeskart</a>
                        <a type="button" href="https://kart.gulesider.no/?c=60.354032,5.242978&z=17&l=aerial&som=0:%27no_realestate:Oslo:0301-207/167:null:null%27" role="button" class="btn btn-success">Eiendomsgrenser</a>
                        <a type="button" href="https://www.dn.no/boligpriser" role="button" class="btn btn-success">BoligPriser</a>
                        </div>
                    </div>
                    <div class="col-sm">
                        <h2 className="text-center mb-4">Plankart Kristiansand</h2>
                        <div class="row justify-content-center">
                            <a type="button" href="https://kommunekart.com/klient/kristiansand/plankart" role="button" class="btn btn-success">Plankart</a>
                        </div>
                    </div>
                </div>
                <h1 className="mb-4"></h1>
            <div class="row">
                    <div class="col-sm">
                        <h2 className="text-center mb-4">YouTube</h2>
                        <div class="row justify-content-center">
                        <a type="button" href="https://www.youtube.com/channel/UCnJZwhPpgQE1bixYb0ooXmw/videos" role="button" class="btn btn-danger">YouTube</a>
                        </div>
                    </div>
                    <div class="col-sm">
                        <h2 className="text-center mb-4">Swing'n'Sweet Jazzklubb</h2>
                        <div class="row justify-content-center">
                        <a type="button" href="http://swing-n-sweet.no/" role="button" class="btn btn-primary">JazzKlubben</a>
                        </div>
                    </div>
                    <div class="col-sm">
                        <h2 className="text-center mb-4">Bank</h2>
                        <div class="row justify-content-center">
                            <a type="button" href="https://www.spv.no/" role="button" role="button" class="btn btn-danger">Sparebanken Vest</a>
                        </div>
                    </div>
                    <div class="col-sm">
                        <h2 className="text-center mb-4">Telefonkatalog</h2>
                        <div class="row justify-content-center">
                            <a type="button" href="https://www.gulesider.no/" role="button" class="btn btn-warning">GuleSider</a>
                        </div>
                    </div>
                </div>
                <h1 className="mb-4"></h1>
                <div class="row">
                    <div class="col-sm">
                        <h2 className="text-center mb-4">OljeFondet</h2>
                        <div class="row justify-content-center">
                        <a type="button" href="https://www.nbim.no/no/" role="button" class="btn btn-primary">Oljefondet</a>
                        </div>
                    </div>
                    <div class="col-sm">
                        <h2 className="text-center mb-4">SSB</h2>
                        <div class="row justify-content-center">
                        <a type="button" href="https://www.ssb.no/" role="button" class="btn btn-info">SSB</a>
                        </div>
                    </div>
                    <div class="col-sm">
                        <h2 className="text-center mb-4">Børsen</h2>
                        <div class="row justify-content-center">
                            <a type="button" href="https://investor.dn.no/#!/Oversikt" role="button" class="btn btn-primary">Børsen</a>
                        </div>
                    </div>
                    <div class="col-sm">
                        <h2 className="text-center mb-4">Stortinget</h2>
                        <div class="row justify-content-center">
                            <a type="button" href="https://www.stortinget.no/nett-tv#storting" role="button" class="btn btn-danger">Stortinget</a>
                        </div>
                    </div>
                </div>
                
        </>
    )
}